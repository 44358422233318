import * as types from '../constants';

const initialState = {
  GISMapGeoJSON : null
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_GEO_JSON:
      return {
        ...state,
        ...actions.payload
      }
    default:
      return state
  }
}