import * as types from '../constants';

let lsReportFilter = localStorage.getItem('semai.reportFilter');

let initialState = null;
if(lsReportFilter){
  initialState = JSON.parse(lsReportFilter);
}else{
  initialState = {
    activity : "",
    fertilizer_id : "",
    start_period : null,
    end_period : null,
    company_id : "",
    estate_id : "",
    afdeling_id : "",
  }
  localStorage.setItem('semai.reportFilter', JSON.stringify(initialState));
}


export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.SET_REPORT_FILTER:
      localStorage.setItem('semai.reportFilter', JSON.stringify(actions.payload));
      return {
        ...state,
        activity : actions.payload.activity,
        fertilizer_id : actions.payload.fertilizer_id,
        start_period : actions.payload.start_period,
        end_period : actions.payload.end_period,
        company_id : actions.payload.company_id,
        estate_id : actions.payload.estate_id,
        afdeling_id : actions.payload.afdeling_id,
      }

    default:
      return state
  }
}