import { combineReducers } from "redux";

import themeReducer from "./themeReducers";
import mapsFilterReducer from "./mapsFilterReducers";
import mapsReducer from "./mapsReducers";
import reportFilterReducer from "./reportFilterReducers";
import authReducer from "./authReducers";
import alertReducer from "./alertReducers";
import dashboardFilterReducer from "./dashboardFilterReducers";

export default combineReducers({
	themeReducer,
	mapsFilterReducer,
	reportFilterReducer,
	authReducer,
	alertReducer,
	mapsReducer,
	dashboardFilterReducer,
});
