import * as types from '../constants';

const initialState = {
    accessToken: "",
    user : null
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.SET_ACCESS_TOKEN:
      // Decode only payload (Referencing example web that was given)
      let parts = actions.payload.accessToken.split('.')
      let user = JSON.parse(atob(parts[1]));
      return {
        ...state,
        accessToken : actions.payload,
        user : user
      }
    case types.CLEAR_AUTH:
      return {
        ...state,
        accessToken : '',
        user : null
      }
    default:
      return state
  }
}