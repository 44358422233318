import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import LogoImg from "../assets/img/logo.svg";

import { CssBaseline, Grid } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 100%;
  min-width: 500px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  ${props => props.theme.breakpoints.up("sm")} {
    &:after {
      content: '';
      position: absolute;
      width:20%;
      right:0;
      height: 100%;
      background: #333333;
      z-index : -1;
    }
  }
`;

const Logo = styled.img`
  ${props => props.theme.breakpoints.up("sm")} {
    width : 538px;
  }
  ${props => props.theme.breakpoints.down("sm")} {
    width : 400px;
  }
`;

const CustomGrid = styled(Grid)`
  display : flex;
  align-items : center;
  justify-content : center;
`;

function AuthCustom({ children }) {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container justify='center' alignItems='center'>
        <CustomGrid item md={7} sm={12}>
          <Logo alt="logo" src={LogoImg} />
        </CustomGrid>
        <CustomGrid item md={5} sm={12}>
            {children}
        </CustomGrid>
      </Grid>
    </Root>
  );
}

export default AuthCustom;
