import * as types from '../constants';

export function setDashboardFilter(value) {
  return {
    type: types.SET_DASHBOARD_FILTER,
    payload: value
  }
}

export function setDashboardEntities(value) {
  return {
    type: types.SET_DASHBOARD_ENTITIES,
    payload: value
  }
}
