import * as types from '../constants';

export function setMapsFilter(value) {
  return {
    type: types.SET_MAPS_FILTER,
    payload: value
  }
}

export function setMapsEntities(value) {
  return {
    type: types.SET_MAPS_ENTITIES,
    payload: value
  }
}
