import * as types from '../constants';

const initialState = {
    company: "",
    estate: "",
    afdeling: "",
    block: "",
    companies : [],
    estates : [],
    afdelings : [],
    blocks : [],
    trees : []
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.SET_MAPS_FILTER:
      return {
        ...state,
        company : actions.payload.company,
        estate : actions.payload.estate,
        afdeling : actions.payload.afdeling,
        block : actions.payload.block,
      }
    case types.SET_MAPS_ENTITIES:
      return {
        ...state,
        ...actions.payload
      }
    default:
      return state
  }
}