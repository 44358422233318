import * as types from '../constants';

const initialState = {
    message: "",
    type : "success",
    open : false
}

// USE ALERT MESSAGE FOR SNACKBAR / ALERT 
export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.SET_ALERT:
      return {
        ...state,
        message : actions.payload.message,
        type : actions.payload.type,
        open : true
      }
    case types.CLEAR_ALERT:
      return {
        ...state,
        message : "",
        open : false
    }
    default:
      return state
  }
}