import { setGeoJSON } from "../redux/actions/mapsActions";
import { checkDataAttributeBool } from "./GeneralHelper";

// return entity level
export const entityLevelMaster = [
	"company",
	"estate",
	"afdeling",
	"block",
	"tree",
];

export const entityLevelMasterPlural = [
	"companies",
	"estates",
	"afdelings",
	"blocks",
	"trees",
];

export const getFilterLevelProps = (mapsFilter) => {
	let props = {
		filterLevel: undefined,
		geometryType: undefined,
	};
	if (mapsFilter.block != "" && mapsFilter.block !== undefined) {
		props.filterLevel = "block";
		props.geometryType = "Point";
		return props;
	} else if (mapsFilter.afdeling != "" && mapsFilter.afdeling !== undefined) {
		props.filterLevel = "afdeling";
		props.geometryType = "Polygon";
		return props;
	} else if (mapsFilter.estate != "" && mapsFilter.estate !== undefined) {
		props.filterLevel = "estate";
		props.geometryType = "Polygon";
		return props;
	} else if (mapsFilter.company != "" && mapsFilter.company !== undefined) {
		props.filterLevel = "company";
		props.geometryType = "Polygon";
		return props;
	} else {
		return props;
	}
};

export const generateGeoJSON = (data, filterLevel, dispatcher) => {
	let dataRef = [];

	switch (filterLevel) {
		case "company":
			dataRef = data.companies;
			break;
		case "estate":
			dataRef = data.estates;
			break;
		case "afdeling":
			dataRef = data.afdelings;
			break;
		case "block":
			dataRef = data.blocks;
			break;
		case "tree":
			dataRef = data.trees;
			break;
		default:
			break;
	}

	let initialPoint = [107.586011836, -6.877129998];
	let strecthRightLeft = 0.005;
	let strecthTopBottom = 0.0009;
	let marginBottom = 0.001;
	let geometryType = filterLevel === "tree" ? "Point" : "Polygon";

	let features = [];

	dataRef.forEach((data) => {
		let geometry = {
			type: geometryType,
			coordinates: [],
		};

		if (geometryType == "Point") {
			geometry.coordinates = [data.longitude, data.latitude];

			// geometry.coordinates = [
			// 	initialPoint[0],
			// 	initialPoint[1] - marginBottom,
			// ];

			// initialPoint = [
			// 	initialPoint[0],
			// 	initialPoint[1] - 2 * marginBottom,
			// ];
		} else {
			let coordinatesObj = checkDataAttributeBool(data, "boundaryJson")
				? JSON.parse(data.boundaryJson)
				: null;

			let hasCoordinatesObj = coordinatesObj !== null;
			if (hasCoordinatesObj) {
				geometry = coordinatesObj;
			} else {
				geometry.coordinates = [
					[
						[
							initialPoint[0] - strecthRightLeft,
							initialPoint[1] + strecthTopBottom,
						],
						[
							initialPoint[0] + strecthRightLeft,
							initialPoint[1] + strecthTopBottom,
						],
						[
							initialPoint[0] + strecthRightLeft,
							initialPoint[1] - strecthTopBottom,
						],
						[
							initialPoint[0] - strecthRightLeft,
							initialPoint[1] - strecthTopBottom,
						],
					],
				];

				initialPoint = [
					initialPoint[0],
					initialPoint[1] - 2 * strecthTopBottom - marginBottom,
				];
			}
		}

		let feature = {
			type: "Feature",
			properties: {
				...data,
			},
			geometry,
		};

		features.push(feature);
	});

	const geojson = {
		type: "FeatureCollection",
		features: features,
	};

	dispatcher(
		setGeoJSON({
			GISMapGeoJSON: geojson,
		})
	);
};
