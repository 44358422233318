export const prefix = ''
export const baseUrl = 'https://backend01.semaigroup.com'
// export const baseUrl = 'http://127.0.0.1:8000/api'
export const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2VtYWlkZXZlbG9wZXIiLCJhIjoiY2tocTBpY2N6M2xrMDJzbDZlOTgzZnIydCJ9.vduoEBwEFEw1OIdRFCOM6Q';

export let requestConfig = {
    headers: { 
        "Accept": '*/*', 
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Credentials':true,
    },
    withCredentials: true,
    mode: 'cors',
}

export const getConfig = (accessToken) =>{
    requestConfig = {
        headers: { 
            ...requestConfig.headers,
            'Authorization': `Bearer ${accessToken}` 
        }
    }
}