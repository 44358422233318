import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  ShoppingCart,
  PieChart,
  Sliders,
  User,
  Users
} from "react-feather";

import {
  Dashboard
} from "@material-ui/icons";


// Auth components
const Login = async(() => import("../pages/auth/Login"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Components components
const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
const Orders = async(() => import("../pages/pages/Orders"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Profile = async(() => import("../pages/pages/Profile"));
const Settings = async(() => import("../pages/pages/Settings"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));

// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Welcome = async(() => import("../pages/docs/Welcome"));
const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
const EnvironmentVariables = async(() => import("../pages/docs/EnvironmentVariables"));
const Deployment = async(() => import("../pages/docs/Deployment"));
const Theming = async(() => import("../pages/docs/Theming"));
const StateManagement = async(() => import("../pages/docs/StateManagement"));
const Support = async(() => import("../pages/docs/Support"));
const Changelog = async(() => import("../pages/docs/Changelog"));

// FROM HERE EDIT
const Overview = async(() => import("../pages/overview"));
// Peta (GIS)
const Peta = async(() => import("../pages/peta"));

// Data View (Report)
const Report = async(() => import("../pages/data-view/Report"));

// Data View (Report Detail)
const ReportDetail = async(() => import("../pages/data-view-detail/ReportDetail"));


const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ],
  component: null
};

const authCustomRoutes = {
  id: "AuthCustom",
  path: "/",
  icon: <Users />,
  children: [
    {
      path: "/login",
      name: "Sign In",
      component: Login
    },
    {
      path: "/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
  ],
  component: null
};

// FROM HERE EDIT
const overviewRoutes = {
  id: "Dashboard",
  path: "/",
  icon: <Dashboard />,
  component: Overview,
  children: null,
};

const mapRoutes = {
  id: "GIS",
  path: "/peta",
  icon: <Map />,
  component: Peta,
  children: null,
};

const TPHRoutes = {
  id: "TPH",
  path: "/TPH",
  icon: <Dashboard />,
  component: Blank,
  children: null,
};

const reportRoutes = {
  id: "Data View",
  path: "/report",
  icon: <Dashboard />,
  component: Report,
  children: null,
};

const reportDetailRoutes = {
  id: "Report Detail",
  path: "/report/detail/:date/:blockId",
  icon: <Dashboard />,
  component: ReportDetail,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  // FROM HERE EDIT
  overviewRoutes,
  mapRoutes,
  TPHRoutes,
  reportRoutes,
  reportDetailRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Auth Custom layout
export const authCustomLayoutRoutes = [authCustomRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  // FROM HERE EDIT
  overviewRoutes,
  mapRoutes,
  // TPHRoutes,
  reportRoutes,
  // reportDetailRoutes,
];
