import React from "react";
import styled from "styled-components";

import {
  Grid,
  Hidden,
  List,
  ListItemText,
  ListItem as MuiListItem
} from "@material-ui/core";
import { connect } from "react-redux";

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(1) / 4}px
    ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

function Footer(props) {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid container item xs={12} md={12} justify="flex-end">
          <List>
            <ListItem>
              <ListItemText primary={`© ${new Date().getFullYear()} - ${props.auth.user ? props.auth.user.company_name  : 'SEMAI'}`} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default connect(store => ({auth : store.authReducer}))(Footer);
