import { getCompany, refreshToken } from "../api/AuthApi";
import { setAccessToken } from "../redux/actions/authActions";
import { setDashboardFilter } from "../redux/actions/dashboardFilterActions";
import { setMapsFilter } from "../redux/actions/mapsFilterActions";

// Check auth to redux store (check token expiry + is currently authenticated)
export const checkAuth = async (reduxProps) => {
  if (reduxProps.auth.user) {
    let isExpired = reduxProps.auth.user.exp < new Date().getTime() / 1000;
    if (isExpired) {
      return await checkRefreshToken(reduxProps);
    } else {
      return true;
    }
  } else {
    return await checkRefreshToken(reduxProps);
  }
};

// check refresh token (use this when the token is expired or the user is currenty not authenticated)
export const checkRefreshToken = async (reduxProps) => {
  let isRefreshed = false;
  // DO REFRESH TOKEN HERE
  let data = await refreshToken();
  if (data.success) {
    reduxProps.dispatch(
      setAccessToken({
        accessToken: data.payload,
      })
    );

    let parts = data.payload.split(".");
    let user = JSON.parse(atob(parts[1]));
    let respCompany = await getCompany(user.company_id);
    if (respCompany.success) {
      reduxProps.dispatch(
        setMapsFilter({
          ...reduxProps.mapsFilter,
          company: respCompany.record,
        })
      );
      reduxProps.dispatch(
        setDashboardFilter({
          ...reduxProps.mapsFilter,
          company: respCompany.record,
        })
      );
    }

    isRefreshed = true;
  }
  return isRefreshed;
};
