import axios from 'axios';
import { baseUrl, requestConfig } from '../Environment.js';
import { returnPayload } from '../helper/GeneralHelper.js';

export async function login(username, password) {
    let body = {
        'username' : username,
        'password' : password
    }
    
    try {
        const resp = await axios.post(`${baseUrl}/auth/login`,
            body,
            requestConfig,
        ).catch(err => {
            if (err.response){
                if (err.response.status === 401) {
                  throw new Error(`Username / Password is Invalid`);
                }
            }
            throw err;
        });

        return returnPayload(true, resp.data.jwt);
    } catch (err) {
        // Handle Error Here
        console.error(err.name);
        console.error(err.message);
        return returnPayload(false, err.message)
    }
}

export async function refreshToken() {
    try {
        const resp = await axios.post(`${baseUrl}/auth/refresh`, {},
            requestConfig,
        ).catch(err => {
            if (err.response.status === 401) {
              throw new Error(`Unauthorized`);
            }
            throw err;
        });

        return returnPayload(true, resp.data.jwt);
    } catch (err) {
        // Handle Error Here
        console.error(err.name);
        console.error(err.message);
        return returnPayload(false, err.message)
    }
}

export async function logout() {
    try {
        const resp = await axios.delete(`${baseUrl}/auth/refresh`,
            requestConfig,
        ).catch(err => {
            if (err.response.status === 401) {
              throw new Error(`Unauthorized`);
            }
            throw err;
        });
        return returnPayload(true, "Log Out Success");
    } catch (err) {
        // Handle Error Here
        console.error(err.name);
        console.error(err.message);
        return returnPayload(false, err.message)
    }
}

export async function getCompany(companyId) {
    try {
        const resp = await axios.get(`${baseUrl}/companies/${companyId}`,
            requestConfig,
        ).catch(err => {
            if (err.response.status === 401) {
              throw new Error(`Unauthorized`);
            }
            throw err;
        });

        return resp.data;
    } catch (err) {
        // Handle Error Here
        console.error(err.name);
        console.error(err.message);
        return returnPayload(false, err.message)
    }
}