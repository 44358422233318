export const SET_THEME = 'SET_THEME';
export const SET_MAPS_FILTER = 'SET_MAPS_FILTER';
export const SET_MAPS_ENTITIES = 'SET_MAPS_ENTITIES';
export const SET_REPORT_FILTER = 'SET_REPORT_FILTER';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const SET_GEO_JSON = 'SET_GEO_JSON';
export const SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER';
export const SET_DASHBOARD_ENTITIES = 'SET_DASHBOARD_ENTITIES';
