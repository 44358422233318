import * as types from '../constants';

export function setAccessToken(value) {
  return {
    type: types.SET_ACCESS_TOKEN,
    payload: value
  }
}

export function clearAuth() {
  return {
    type: types.CLEAR_AUTH,
  }
}
