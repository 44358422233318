import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboardLayoutRoutes, authLayoutRoutes, authCustomLayoutRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import AuthCustomLayout from "../layouts/AuthCustom";
import Page404 from "../pages/auth/Page404";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        // CHECK FOR CURRENT TOKEN HERE TO REDIRECT FROM LOGIN TO DASHBOARD "/"
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = () => {
  return (
    <Router>
      <Switch>
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        {childRoutes(AuthCustomLayout, authCustomLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
}


export default (Routes);
