// return to component with number separated by commas (.)
export const numberWithCommas = (n) => {
	return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// return to component with format
// {
//     success : ${success},
//     payload : ${payload}
// }
// e.g
// {
//     success : false,
//     payload : "Username Password Invalid"
// }
// -------------------------------------------
// {
//     success : true,
//     payload : [{id:1,name:Budiman},{id:2,name:Santo}]
// }
export const returnPayload = (success, payload) => ({ success, payload });

// check data inside object get "key" from "data", if doesn't exist return "defaultValue"
export const checkDataAttribute = (data, key, defaultValue) => {
	if (data) {
		return data[key] !== null && data[key] !== undefined && data[key] !== ""
			? data[key]
			: defaultValue;
	} else {
		return defaultValue;
	}
};

// check data inside object get "key" from "data", return boolean value according to data existence
export const checkDataAttributeBool = (data, key) => {
	if (data) {
		return data[key] !== null && data[key] !== undefined && data[key] !== ""
			? true
			: false;
	} else {
		return false;
	}
};

// return number with padded 0 infront
export const paddedNumber = (n, width, z) => {
	z = z || "0";
	n = n + "";
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const roundNumber = (n, roundValue) => {
	var num = Number(n); // The Number() only visualizes the type and is not needed
	var roundedString = num.toFixed(roundValue);
	var rounded = Number(roundedString);
	return rounded;
};

// format date limited to only these part dd, mm, yyyy, hh, ii, ss
export const formatDate = (date, format) => {
  let res = format;
	let parts = {
		dd: String(date.getDate()).padStart(2, "0"),
		mm: String(date.getMonth() + 1).padStart(2, "0"),
		yyyy: date.getFullYear(),
		hh: String(date.getHours()).padStart(2, "0"),
		ii: String(date.getMinutes()).padStart(2, "0"),
		ss: String(date.getSeconds()).padStart(2, "0"),
	};

  let partsName = ["dd", "mm", "yyyy", "hh", "ii", "ss"];
  let formatRecognized = false;
	partsName.forEach((part) => {
    let needReplacement = format.includes(part);
    if(needReplacement){
      res = res.replace(part,parts[part])
      formatRecognized = true;
    }
  });

  return formatRecognized ? res : null;
};
