import { Grid } from "@material-ui/core";
import Stats from "./Stats";
import { checkDataAttribute, checkDataAttributeBool, numberWithCommas } from "../helper/GeneralHelper";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getEntitySummaries } from "../api/MapsApi";
import { checkAuth } from "../helper/AuthHelper";

const HeaderPanel = (props) =>{
  const [companySummary, setCompanySummary] = useState(null)

  const fetchCompanySummary = async() => {
    if(checkDataAttributeBool(props.auth, 'user')){
      setCompanySummary(await getEntitySummaries({
        entityId : props.auth.user.company_id, 
        entityLevel : 'companies',
        filterLevel : 'company' 
      }));
    }
  }

  useEffect(() => {
    async function checkIsAuthenticated() {
      let isAuthenticated = await checkAuth(props);
      if(isAuthenticated){
        fetchCompanySummary();
      }
    }
    checkIsAuthenticated()
  }, [])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={12} md={2} lg={2} xl>
        <Stats
          title="Estate Aktif"
          amount={numberWithCommas(checkDataAttribute(companySummary, 'estateCount', 0))}
          cardColor="#EB5757"
          textColor="white"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2} xl>
        <Stats
          title="Afdeling Aktif"
          amount={numberWithCommas(checkDataAttribute(companySummary, 'afdelingCount', 0))}
          cardColor="#FFB024"
          textColor="white"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2} xl>
        <Stats
          title="Blok Aktif"
          amount={numberWithCommas(checkDataAttribute(companySummary, 'blockCount', 0))}
          cardColor="#058587"
          textColor="white"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl>
        <Stats
          title="Tanaman Menghasilkan"
          amount={numberWithCommas(checkDataAttribute(companySummary, 'activeTreeCount', 0))}
          cardColor="#2D9CDB"
          textColor="white"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl>
        <Stats
          title="Sinkronisasi Data"
          amount="225/278"
          cardColor="#BB6BD9"
          textColor="white"
        />
      </Grid>
    </Grid>
  )
}

export default connect(store => ({
  auth : store.authReducer
}))(HeaderPanel);