import axios from "axios";
import { baseUrl, requestConfig } from "../Environment.js";
import { returnPayload } from "../helper/GeneralHelper.js";
import { entityLevelMaster } from "../helper/MapsHelper.js";

export async function getActivityByFilterLevel(params) {
	let { entityLevel, entityId, year, month, date } = params;
	if (entityId !== undefined) {
		try {
			const resp = await axios
				.get(
					`${baseUrl}/${entityLevel}/${entityId}/activity-aggregates/daily/${year}/${month}/${date}`,
					{},
					requestConfig
				)
				.catch((err) => {
					throw err;
				});

			return resp.data;
		} catch (err) {
			// Handle Error Here
			console.error(err.name);
			console.error(err.message);
			return returnPayload(false, err.message);
		}
	}
}

export async function getEntitySummaries(params) {
	let { entityLevel, entityId, filterLevel } = params;

	let entityLevelIndex = entityLevelMaster.indexOf(filterLevel);
	let lowerOneEntity = entityLevelMaster[entityLevelIndex + 1];

	if (entityId !== undefined) {
		try {
			const resp = await axios
				.get(
					`${baseUrl}/${entityLevel}/${entityId}/${lowerOneEntity}-summaries`,
					{},
					requestConfig
				)
				.catch((err) => {
					throw err;
				});

			return resp.data;
		} catch (err) {
			// Handle Error Here
			console.error(err.name);
			console.error(err.message);
			return returnPayload(false, err.message);
		}
	}
}

export async function getActivitesByType(type, body) {
	try {
		const resp = await axios
			.get(`${baseUrl}/${type}-activities`, body, requestConfig)
			.catch((err) => {
				throw err;
			});

		return resp.data;
	} catch (err) {
		// Handle Error Here
		console.error(err.name);
		console.error(err.message);
		return returnPayload(false, err.message);
	}
}

export async function getTreeHoverActivity(body) {
	try {
		let chemistParam = {
			...body.params,
			fltFertilizer: 6,
		};
		const [
			harvestingRes,
			fertilizingRes,
			pruningRes,
			chemistRes,
		] = await Promise.all([
			axios.get(`${baseUrl}/harvesting-activities`, body, requestConfig),
			axios.get(`${baseUrl}/fertilizing-activities`, body, requestConfig),
			axios.get(`${baseUrl}/pruning-activities`, body, requestConfig),
			axios.get(
				`${baseUrl}/fertilizing-activities`,
				{ params: chemistParam },
				requestConfig
			),
		]);
		let data = {
			harvestingActivities: harvestingRes.data,
			fertilizingActivities: fertilizingRes.data,
			pruningActivities: pruningRes.data,
			chemistActivities: chemistRes.data,
		};
		return data;
	} catch (err) {
		// Handle Error Here
		console.error(err.name);
		console.error(err.message);
		return returnPayload(false, err.message);
	}
}

export async function getLandSummaryByEntity(lowerOneEntity, entityId, body) {
	try {


		const resp = await axios
			.get(
				`${baseUrl}/${lowerOneEntity}/${entityId}/land-summary`,
				body,
				requestConfig
			)
			.catch((err) => {
				throw err;
			});

		return resp.data;
	} catch (err) {
		// Handle Error Here
		console.error(err.name);
		console.error(err.message);
		return returnPayload(false, err.message);
	}
}
