import * as types from '../constants';

export function setAlert(value) {
  return {
    type: types.SET_ALERT,
    payload: value
  }
}

export function clearAlert() {
  return {
    type: types.CLEAR_ALERT
  }
}
