import React from "react";
import styled from "styled-components";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${props => props.theme.spacing(4)}px;
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.common.white};
  margin-bottom: ${props => props.theme.spacing(4)}px;

  span {
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const Percentage = styled(MuiTypography)`
  color: ${props => props.theme.palette.grey[600]};

  span {
    color: ${props => props.percentagecolor};
    padding-right: 10px;
    font-weight: ${props => props.theme.typography.fontWeightBold};
  }
`;

const TypographyTitle = styled(Typography)`
  font-size : 16px;
`;

const TypographyValue = styled(Typography)`
  font-size : 50px;
`;

function Stats({ title, amount, cardColor, textColor }) {
  return (
    <Card mb={3}
      style={{
        background : cardColor,
        color : textColor,
      }}
    >
      <CardContent>
        <TypographyTitle variant="h6" mb={3}>
          {title}
        </TypographyTitle>
        <Divider mb={3} style={{
          backgroundColor : textColor,
          width : '135px',
        }} />
        <TypographyValue variant="h6" mb={3}>
            {amount}
        </TypographyValue>
      </CardContent>
    </Card>
  );
}

export default Stats;
