import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Settings from "../components/Settings";

import { compose, spacing } from "@material-ui/system";
import {
	Hidden,
	CssBaseline,
	Paper as MuiPaper,
	withWidth,
	Grid,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkAuth } from "../helper/AuthHelper";
import Loader from "../components/Loader";
import HeaderPanel from "../components/HeaderPanel";

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
	display: flex;
	min-height: 100vh;
`;

const Drawer = styled.div`
	${(props) => props.theme.breakpoints.up("md")} {
		width: ${drawerWidth}px;
		flex-shrink: 0;
	}
`;

const AppContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
	flex: 1;
	background: ${(props) => props.theme.body.background};

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex: none;
	}

	.MuiPaper-root .MuiPaper-root {
		box-shadow: none;
	}
`;

const Dashboard = ({ children, routes, width, ...rest }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		async function checkIsAuthenticated() {
			let isAuthenticated = await checkAuth(rest);
			setLoading(true);
			if (!isAuthenticated) {
				history.push("/login");
			}
		}
		checkIsAuthenticated();
	}, []);

	const [mobileOpen, setMobileOpen] = useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	if (loading) {
		return (
			<Root>
				<CssBaseline />
				<GlobalStyle />
				<Drawer>
					<Hidden mdUp implementation="js">
						<Sidebar
							routes={routes}
							PaperProps={{ style: { width: drawerWidth } }}
							variant="temporary"
							open={mobileOpen}
							onClose={handleDrawerToggle}
						/>
					</Hidden>
					<Hidden smDown implementation="css">
						<Sidebar
							routes={routes}
							PaperProps={{ style: { width: drawerWidth } }}
						/>
					</Hidden>
				</Drawer>
				<AppContent>
					<Header onDrawerToggle={handleDrawerToggle} />
					<MainContent p={isWidthUp("lg", width) ? 10 : 5}>
						<HeaderPanel />
						{children}
					</MainContent>
					<Footer />
				</AppContent>
				{/* <Settings /> */}
			</Root>
		);
	} else {
		return (
			<Grid
				container
				justify="center"
				alignItems="center"
				style={{
					minHeight: "100vh",
				}}
			>
				<Grid item xs={12}>
					<Loader />
				</Grid>
			</Grid>
		);
	}
};

const enhance = compose(
	withWidth(),
	connect((store) => ({
		auth: store.authReducer,
		mapsFilter: store.mapsFilterReducer,
	}))
);
export default enhance(Dashboard);
